<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-layout wrap>
      <v-col cols="12" md="3">
        <UserMenu></UserMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">
        <v-row class="rowBg">
          <v-col cols="6">
            <h5>
              <v-icon size="25" color="#2776ED" class="mr-4"
                >mdi-information</v-icon
              >
              User Wallet History
            </h5>
          </v-col>
          <v-col cols="6" align="right">
                <v-btn
                  to="/user/en/wallet"
                  class="ma-2"
                  outlined
                  color="#72BD60"
                >
                  Wallet
                </v-btn>
            </v-col>
        </v-row>

        <v-row wrap class="mt-2">
          <v-col cols="12" v-if="transactionHistories.length >= 1">
            <v-layout
              class="rowBg mt-2"
              v-for="(transaction, index) in transactionHistories"
              :key="index"
            >
              <v-col cols="2" class="hidden-sm-and-down">
                <v-icon size="30" color="#72BD61">mdi-history</v-icon>
              </v-col>
              <v-col cols="8" md="6" align="left">
                <h6>{{ transaction.description }}</h6>
                <p>{{ transaction.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</p>
              </v-col>
              <v-col cols="4" align="right">
                <p class="titleStyleColorSm">
                  {{ systemSetting.systemCurrency }}{{ transaction.amount }}
                </p>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import UserMenu from "../others/UserMenu.vue";

export default {
  name: "UserWalletHistory",

  components: {
    UserMenu,
  },

  data: () => ({
    loader: false,
    dialogFundWallet: false,
    dialogWalletLogin: false,
    dialogEmailConfirmation: false,
    dialogVerifyEmail: false,
    dialogChangePin: false,
    dialogChangePinSuccess: false,
    dialogPaymentMethods: false,
    modalSuccess:false,
    walletLocked: false,
    amount:"",
    paystackkey: process.env.VUE_APP_PAYSTACKKEY, //paystack public key
    reference:"",
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/#/overview/user/dashboard/en",
      },
      {
        text: "Wallet",
        disabled: true,
        href: "/",
      },
    ],
    show3: false,
    errorMessage: "",
    walletLogin: {
      pin: "",
    },
    walletId: "",
    verificationCode: {
      code: "",
    },
    userDetail: {},
    transactionHistories: [],
    systemSetting: {
      systemCurrency: "GH₵",
    },
    userId: "",
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 4 || "Max 4 digits",
    },
  }),
  mounted() {
    this.setAuthenticatedUser();
    this.fetchWalletHistory();
  },
  methods: {
    setAuthenticatedUser() {
      this.userId = localStorage.getItem("userId");
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + this.userId)
        .then((response) => {
          this.userDetail = response.data.data;
          this.walletId = response.data.data.Wallet.walletId;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    fetchWalletHistory() {
      this.$http
        .get(`${process.env.VUE_APP_URL}wallet/` + this.userId)
        .then((response) => {
          this.transactionHistories = response.data.data.WalletHistories.reverse();
        })
        .catch((error) => {
          this.errorMessage = error.response.data.data;
        });
    },
    requestLink() {
      this.$http
        .post(`${process.env.VUE_APP_URL}wallet/send-email/` + this.userId)
        .then((response) => {
          if (response.data.success == true) {
            this.dialogWalletLogin = false;
            this.dialogEmailConfirmation = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.data;
        });
    },
    VerifyEmail() {
      this.dialogEmailConfirmation = false;
      this.dialogVerifyEmail = true;
    },
    verifyCode() {
      this.$http
        .post(
          `${process.env.VUE_APP_URL}wallet/confirm-email-pin/` +
            this.userId,
          this.verificationCode
        )
        .then((response) => {
          if (response.data.success == true) {
            this.dialogVerifyEmail = false;
            this.dialogChangePin = true;
          }
        })
        .catch((error) => {
          this.errorMessage = error.response.data.data;
        });
    },
    changePin() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(
          `${process.env.VUE_APP_URL}wallet/set-pin/` + this.userId,
          this.walletLogin
        )
        .then((response) => {
          if (response.data.success == true) {
            this.loader = false;
            this.dialogChangePin = false;
            this.dialogChangePinSuccess = true;
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
    addFund() {
      this.dialogFundWallet = false;
      this.dialogPaymentMethods = true;
    },
    loginWallet() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(
          `${process.env.VUE_APP_URL}wallet/login/` + this.userId,
          this.walletLogin
        )
        .then((response) => {
          if (response.data.success == true) {
            this.loader = false;
            this.dialogWalletLogin = false;
            this.walletLocked = true;
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
    confirmWalletPin() {
      this.dialogChangePinSuccess = false;
      this.walletLocked = true;
    },
    callback: function(response) {

      if (response.status == "success") {
        this.reference = response.reference;
        this.userId = localStorage.getItem("userId");

        const data = {
          amount: this.amount,
          description: 'Wallet credit',
        }

        this.$http
        .post(
          `${process.env.VUE_APP_URL}wallet/credit/` + this.walletId,
          data
        )
        .then(() => {
          this.dialogPaymentMethods = false;
          this.modalSuccess = true;
        })
        .catch((error) => {
          console.log(error.response);
        });

      }
    },
    close: function() {
      console.log("Payment closed");
    },
    reloadPage(){
      location.reload();
    }
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0px 0px 0px 10px;
}

.iconStyle {
  font-size: 40px;
  color: #dd6464;
  transform: rotate(180deg);
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.titleStyleColor {
  width: 411px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.titleStyleColorSm {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
